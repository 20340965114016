<template>
  <div class="home">
    <HeadBox></HeadBox>
    <router-view></router-view>
    <FooterBox></FooterBox>
  </div>
</template>

<script>


import HeadBox from '@/common/header.vue'
import FooterBox from '@/common/footer.vue'

export default {
  name: 'Index',
  components: {
    HeadBox,
    FooterBox,
  }
}
</script>

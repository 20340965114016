import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../common/IndexView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    name: 'index',
    component: IndexView,
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import('../views/home.vue')
      },
      {
        path: "/news",
        name: "news",
        component: () => import('../views/news.vue')
      },
      {
        path: "/newsArticle",
        name: "newsArticle",
        component: () => import('../views/news_article.vue')
      },
      {
        path: "/industry",
        name: "industry",
        component: () => import('../views/industry.vue'),
      },
      {
        path: "/experts",
        name: "experts",
        component: () => import('../views/experts.vue'),
      },
      {
        path: "/expertsArticle",
        name: "expertsArticle",
        component: () => import('../views/experts_article.vue'),
      },
      {
        path: "/about",
        name: "about",
        component: () => import('../views/about.vue')
      },
      // 登录测试页面
      {
        path: "/register_login",
        name: "register_login",
        component: () => import('../views/register_login.vue')
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router

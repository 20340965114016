import axios from 'axios';

// 导入全部接口
import dataConfig from '../api/index';

// 在单独js中使用element-ui
//1.引入vue
import Vue from 'vue';
//2.新创建一个vue实例
let v = new Vue();

axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);


// 拦截后台返回给前端得json 数据-
axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            if (response.data.status == '0000') {
                return response.data;
            } else {
                v.$message.error(response.data.msg);
                return response.data;
            }
        } else {
            Promise.reject(response);
        }
    },
    error => {
        // console.log(error);
        return Promise.reject(error);
    }
);
// 前缀-请求地址

axios.defaults.baseURL = "https://www.eincip.com/zl/api";
// axios.defaults.baseURL = "http://182.92.200.92:8091/zl-ds-dc-rest";


// 超时时间10分钟
// 60秒
axios.defaults.timeout = 600000;

var http = function(urlM, data_obj, parmas_obj) {
    // 请求地址 和 方法
    const methods = dataConfig[urlM].method;
    const urls = axios.defaults.baseURL + dataConfig[urlM].url;
    const datas = data_obj || {};
    const Parmas = parmas_obj || {};
    const header = dataConfig[urlM].Headers || {};
    return new Promise((resolve, reject) => {
        const loading = v.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: document.querySelector('.hidenShow')
        });
        axios({
            method: methods,
            url: urls,
            data: datas,
            params: Parmas,
            headers: {
                token:sessionStorage.getItem('token') ||'',
                ...header
            },
        }).then(res => {
            resolve(res);
            // 结束
            loading.close();
        }).catch(err => {
            reject(err);
            // 结束
            loading.close();
        })
    })

}

var httpNoloding = function(urlM, data_obj, parmas_obj) {
    // 请求地址 和 方法
    const methods = dataConfig[urlM].method;
    const urls = axios.defaults.baseURL + dataConfig[urlM].url;
    const datas = data_obj || {};
    const Parmas = parmas_obj || {};
    const header = dataConfig[urlM].Headers || {};
    return new Promise((resolve, reject) => {
        axios({
            method: methods,
            url: urls,
            data: datas,
            params: Parmas,
            headers: {
                token:sessionStorage.getItem('token') || '',
                ...header
            },
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })

}

var httpFile = function(urlM, data_obj, parmas_obj) {
    // 请求地址 和 方法
    const methods = dataConfig[urlM].method;
    const urls = axios.defaults.baseURL + dataConfig[urlM].url;
    const datas = data_obj || {};
    const Parmas = parmas_obj || {};
    const header = dataConfig[urlM].Headers || {};
    return new Promise((resolve, reject) => {
        axios({
            method: methods,
            url: urls,
            data: datas,
            params: Parmas,
            responseType: "blob", //接收类型设置，否者返回字符型 这个一定要在headers之前 不然zip解压后失败，文件乱码
            headers: {
                token:sessionStorage.getItem('token'),
                ...header
            },
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })

}

export default { http, httpFile,httpNoloding }
<template>
  <div class="footer_box">
    <div class="wp">
      <div class="footer_flex">
        <div class="right_box">
          <h2>河北国维知识产权咨询有限公司</h2>
          <p>联系热线: 0311-66560677</p>
          <p>邮箱: new@chinagowell.com/win@chinag</p>
          <p>地址: 河北省石家庄高新区祁连街95号慧谷大厦A座2层,B座2层、B座9层</p>
        </div>
        <div class="left_box">
          <h2>友情链接</h2>
          <p>
            <a href="http://scjg.sjz.gov.cn" target="_blank"
              >石家庄市市场监督管理局</a
            >
          </p>
          <p>
            <a href="http://www.shidz.gov.cn" target="_blank"
              >石家庄高新技术产业开发区管理委员会</a
            >
          </p>
        </div>
        <div class="people_box">网站访问人数<span>{{peopleNumber}}</span></div>
      </div>
      <div class="copyright">
        <p>
          Copyright &copy; 2022-2032 河北国维知识产权咨询有限公司 版权所有
          网站备案号：<a href="https://beian.miit.gov.cn/" target="_blank"
            >冀ICP备11011134号-3</a
          >
        </p>
      </div>
    </div>
    <!-- 左侧固定位置 -->
    <div class="fixed_box">
      <ul>
        <li @click="openAlert">
          <img src="@/assets/img/f_ask.png" alt="咨询" />
          <i>业务咨询</i>
        </li>
        <li class="tel_hide">
          <img src="@/assets/img/f_tel.png" alt="电话" />
          <i>电话</i>
          <div class="tel_box">0311-66560677</div>
        </li>
        <li class="gzh_hide">
          <img src="@/assets/img/gzh_icont.png" alt="公众号" />
          <i>公众号</i>
          <div class="gzh_show">
            <img src="@/assets/img/gzh.png" alt="公众号" />
          </div>
        </li>
        <li @click="toTop">
          <img src="@/assets/img/f_top.png" alt="置顶" />
          <i>置顶</i>
        </li>
      </ul>
    </div>
    <!-- 弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="860px"
      :close-on-click-modal="false"
      @close="cancelAlert"
    >
      <div class="consult_wrap">
        <div class="left_wrap">
          <div class="tit_1">
            <h2>BUSINESS</h2>
            <h2>CONSULTING</h2>
          </div>
          <div class="hr_wrap"></div>
          <h3>业务咨询</h3>
          <p>请提出您的需求，我们收到后会第一时间回复您</p>
          <div class="arrow_wrap">
            <img src="@/assets/img/arrow_bg.png" alt="咨询" />
          </div>
        </div>
        <div class="right_wrap">
          <el-form ref="form" :model="form" :rules="rules" label-width="0px">
            <el-form-item prop="name">
              <el-input
                v-model="form.name"
                placeholder="你的名字"
                prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input
                type="number"
                maxlength="11"
                oninput="if(value.length > 11) value=value.slice(0, 11)"
                v-model.trim="form.phone"
                placeholder="电话"
                prefix-icon="el-icon-phone-outline"
              ></el-input>
            </el-form-item>
            <el-form-item prop="e_mail">
              <el-input
                v-model="form.e_mail"
                placeholder="邮箱"
                prefix-icon="el-icon-message"
              ></el-input>
            </el-form-item>
            <el-form-item prop="content">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入您想说的内容"
                v-model="form.content"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <div class="input_img">
                <el-input
                  placeholder="请输入验证码"
                  v-model="form.code"
                  @keyup.enter.native="submitForm()"
                ></el-input>
                <img :src="imgCode" alt="验证码" @click="getCode" />
              </div>
            </el-form-item>
          </el-form>
          <div class="btn_wrap" @click="submitForm">发送信息</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request.js";
export default {
  data() {
    return {
      peopleNumber: 0, //访问人数
      dialogVisible: false,
      form: {
        name: "",
        phone: "",
        e_mail: "",
        content: "",
        code: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        e_mail: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        content: [
          { required: true, message: "请输入咨询的内容", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      imgCode: "", //图片验证码
      key: "", //
    };
  },
  created() {
    this.getCode();
    this.getPerson();
  },
  methods: {
    // 获取访问人数
    getPerson() {
      request.http("getAccount").then((res) => {
        if (res.status == "0000") {
          let data = res.rows[0];
          this.peopleNumber = data.num;
        }
      });
    },
    openAlert() {
      this.dialogVisible = true;
    },
    toTop() {
      // 实现滚动效果
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    getCode() {
      request.http("getImgCode").then((res) => {
        if (res.status == "0000") {
          this.imgCode = res.image;
          this.key = res.key;
        }
      });
    },
    cancelAlert() {
      this.dialogVisible = false;
      this.$refs.form.resetFields(); //重置表格
      this.form = {
        name: "",
        phone: "",
        e_mail: "",
        content: "",
        code: "",
      };
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.key = this.key;
          request.http("addConsulting", this.form).then((res) => {
            if (res.status == "0000") {
              this.$message.success("提交成功");
              this.cancelAlert();
            } else {
              // 如果密码错误或者用户不正确刷新验证码;
              this.getCode();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer_box {
  width: 100%;
  padding: 30px 0;
  background: #04327b;
}
.wp {
  .footer_flex {
    .flexBox(@a:flex-end);
    .left_box {
      padding-right: 20px;
      h2 {
        color: #fff;
        font-size: 18px;
        padding-bottom: 10px;
      }
      p {
        a {
          color: #fff;
          font-size: 16px;
          line-height: 2em;
        }
      }
    }
    .right_box {
      width: 450px;
      h2 {
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 10px;
      }
      p {
        color: #fff;
        font-size: 14px;
        line-height: 2em;
      }
    }
  }
  .copyright {
    padding-top: 20px;
    .flexBox(@j:center);
    p {
      color: #fff;
      font-size: 14px;
      a {
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

.fixed_box {
  position: fixed;
  right: 2px;
  bottom: 100px;
  ul {
    li {
      cursor: pointer;
      width: 60px;
      height: 60px;
      background: #04327b;
      margin-bottom: 3px;
      .flexBox(@j:center,@r:column);
      a {
        .flexBox(@j:center,@r:column);
        width: 60px;
        height: 60px;
        background: #04327b;
      }
      img {
        width: 28px;
        height: 28px;
      }
      i {
        color: #fff;
        font-size: 12px;
        margin-top: 4px;
        font-style: normal;
        height: 17px;
      }
      &.tel_hide {
        position: relative;
        &:hover {
          .tel_box {
            right: 62px;
          }
        }
        .tel_box {
          position: absolute;
          z-index: -1;
          right: -200px;
          width: 140px;
          height: 60px;
          line-height: 60px;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          background: #04327b;
          transition: all ease 300ms;
        }
      }
      &.gzh_hide {
        position: relative;
        &:hover {
          .gzh_show {
            right: 62px;
          }
        }
        .gzh_show {
          position: absolute;
          z-index: -1;
          right: -200px;
          width: 150px;
          height: 150px;
          padding: 10px;
          background: #04327b;
          transition: all ease 300ms;
          img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}
.consult_wrap {
  .flexBox();
  .left_wrap {
    width: 201px;
    height: 358px;
    background: #e8edf5;
    padding: 22px;
    box-sizing: border-box;
    position: relative;
    .tit_1 {
      margin-top: 30px;
      margin-bottom: 42px;
      h2 {
        color: #cdd3df;
        font-size: 22px;
        font-weight: bold;
        line-height: 26px;
      }
    }
    .hr_wrap {
      width: 30px;
      height: 3px;
      background: #0e59e4;
      border: 1px solid #0e59e4;
      margin-bottom: 10px;
    }
    h3 {
      color: #000;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 82px;
    }
    p {
      color: #808080;
      font-size: 12px;
      line-height: 17px;
    }
    .arrow_wrap {
      position: absolute;
      right: -8px;
      bottom: 125px;
      width: 29px;
      height: 39px;
      img {
        width: 29px;
        height: 39px;
        display: block;
      }
    }
  }
  .right_wrap {
    width: 564px;
    & /deep/ .el-input__icon {
      color: #0e59e4;
      font-weight: bold;
      font-size: 20px;
    }
    & /deep/ input::-webkit-input-placeholder {
      color: #000;
    }
    .btn_wrap {
      cursor: pointer;
      width: 564px;
      height: 38px;
      color: #fff;
      .flexBox(@j:center);
      font-size: 12px;
      background: #0e59e4;
    }
  }
}

.input_img {
  .flexBox;
  img {
    margin-left: 10px;
    width: 100px;
    height: 38px;
  }
}

.people_box{
  color: #fff;
  font-size: 16px;
  span{
    color: #fff;
    font-size: 16px;
    margin-left:10px;
  }
}
</style>
export default {
    // 获取图片验证码
    getImgCode: {
        url: "/account/getImgCode",
        method: 'GET',
        Headers: {
            'Content-Type': 'multipart/form-data'
        }
    },
    // 添加业务咨询 
    addConsulting: {
        url: "/consulting/addConsulting",
        method: 'post',
        Headers: {
            'Content-Type': 'application/json'
        }
    },
    // 获取消息推送列表
    getNewsList: {
        url: "/news/getNewsList",
        method: 'get',
        Headers: {
            'Content-Type': 'application/json'
        }
    },
    // 专家列表
    getExpertsList: {
        url: "/experts/getExpertsList",
        method: 'get',
        Headers: {
            'Content-Type': 'application/json'
        }
    },
    // 专利数据跳转所用账号数据
    zldbLogin: {
        url: "/account/zldbLogin",
        method: 'get',
        Headers: {
            'Content-Type': 'multipart/form-data'
        }
    },
    // 合作伙伴列表  (改成 团队风采)
    getPartnersList: {
        url: "/partners/getPartnersList",
        method: 'get',
        Headers: {
            'Content-Type': 'application/json'
        }
    },
    // 获取关于我们 
    getAboutMe: {
        url: "/aboutMe/getAboutMe",
        method: 'get',
        Headers: {
            'Content-Type': 'multipart/form-data'
        }
    },
    //访问量统计添加
    addAccount: {
        url: "/account/addAccount",
        method: 'get',
        Headers: {
            'Content-Type': 'multipart/form-data'
        }
    },
    // 访问量统计展示
    getAccount: {
        url: "/account/getAccount",
        method: 'get',
        Headers: {
            'Content-Type': 'multipart/form-data'
        }
    },

    // 获取验证码
    getImgCode: {
        url: "/account/getImgCode",
        method: 'get',
        Headers: {
            'Content-Type': 'multipart/form-data'
        }
    },
    // 前端用户注册
    userRegistered: {
        url: "/account/userRegistered",
        method: 'post',
        Headers: {
            'Content-Type': 'application/json'
        }
    },
    // 前端用户登录
    account_login: {
        url: "/account/login",
        method: 'get',
        Headers: {
            'Content-Type': 'multipart/form-data'
        }
    },
}
<template>
  <div class="header_box">
    <div class="logo_wrap">
      <h1>石家庄高新区新一代电子信息产业专利管理平台</h1>
    </div>
    <ul class="nav_ul">
      <li
        v-for="(item, index) in NavList"
        :key="index"
        :class="idx == index ? 'active' : ''"
        @click="handleNav(item, index)"
      >
        {{ item.name }}
      </li>
    </ul>
    <!-- <div class="phone_wrap">
      <img src="@/assets/img/tel.png" alt="电话" />
      <a href="tel:0311-66560677">0311-66560677</a>
    </div> -->
  </div>
</template>

<script>
import request from "@/utils/request.js";
export default {
  data() {
    return {
      NavList: [
        { id: 1, name: "首页", router: "/home" },
        { id: 2, name: "公共信息推送", router: "/news" },
        { id: 3, name: "产业专利数据库",router:"/register_login" },
        { id: 4, name: "产业全景", router: "/industry" },
        { id: 5, name: "专家库", router: "/experts" },
        { id: 6, name: "关于我们", router: "/about" },
      ],
      idx: 0,
      bodyContent: "",
    };
  },
  methods: {
    handleNav(e, i) {
      let { router } = e;
      this.idx = i;
      // if (!this.$route.path.includes(router)) {
      //   //当前路径中不包括本path就执行
      //   this.$router.push(router);
      // }
      if (router) {
        this.$router.push(router);
      }
    },   
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号
      handler(to, from) {
        let path = to.path; //当前路由地址
        this.NavList.forEach((item, index) => {
          if (item.router == path) {
            this.idx = index;
          }
        });
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
};
</script>

<style lang="less" scoped>
.header_box {
  padding: 32px;
  background: #fff;
  .flexBox(@j:space-around);
  .logo_wrap {
    h1 {
      font-size: 26px;
      color: #023265;
      font-weight: bold;
    }
  }
  .nav_ul {
    .flexBox();
    border-right: 1px solid #cad6e0;
    li {
      cursor: pointer;
      color: #181818;
      font-weight: 400;
      font-size: 18px;
      padding-bottom: 5px;
      font-weight: bold;
      border-bottom: 2px solid transparent;
      margin:0 10px;
      &:first-child{
        margin-left: 0;
      }
      &.active {
        color: #0e59e4;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 2px solid #0e59e4;
      }
    }
  }
  .phone_wrap {
    .flexBox(@j:flex-start);
    
    img {
      margin-right: 13px;
      width: 22px;
      height: 22px;
    }
    a {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
      line-height: 20px;
    }
  }
}
</style>